import { RouteObject } from 'react-router-dom';
import { RouteMeta } from '@/pages/routes';

const route: RouteObject = {
	path: 'reports',
	loader: () => ({
		meta: {
			title: 'Reports',
			htmlTitlePattern: '#title :: Reports',
		} as RouteMeta,
	}),
	lazy: async () => ({ Component: (await import('@/modules/reports/Layout')).default }),
	children: [
		{
			path: 'insurance/payments',
			index: true,
			id: 'reportInsurancePayments',
			loader: () => ({
				meta: {
					title: 'Insurance Payments',
					disabled: true,
				} as RouteMeta,
			}),
			lazy: async () => ({ Component: (await import('@/modules/reports/pages/Payments.tsx')).default }),
		},
		{
			path: 'medications/status',
			index: true,
			id: 'reportMedicationsStatus',
			loader: () => ({
				meta: {
					title: 'Medications Status',
					disabled: true,
				} as RouteMeta,
			}),
			lazy: async () => ({ Component: (await import('@/modules/reports/pages/StatusReport.tsx')).default }),
		},
		{
			path: 'medications/processedClaims',
			index: true,
			id: 'reportProcessedClaims',
			loader: () => ({
				meta: {
					title: 'Processed Claims',
					disabled: true,
				} as RouteMeta,
			}),
			lazy: async () => ({ Component: (await import('@/modules/reports/pages/ProcessedClaims.tsx')).default }),
		},
		{
			path: 'patients',
			index: true,
			id: 'reportPatients',
			loader: () => ({
				meta: {
					title: 'Patients',
					disabled: true,
				} as RouteMeta,
			}),
			lazy: async () => ({ Component: (await import('@/modules/reports/pages/Patients.tsx')).default }),
		},
		{
			path: 'drug/classification',
			index: true,
			id: 'drugClassification',
			loader: () => ({
				meta: {
					title: 'Drug Classification',
					disabled: true,
				} as RouteMeta,
			}),
			lazy: async () => ({ Component: (await import('@/modules/reports/pages/DrugClassification.tsx')).default }),
		},
		{
			path: 'pbx',
			loader: () => ({
				meta: {
					title: 'PBX',
					htmlTitlePattern: '#title :: PBX Reports',
				} as RouteMeta,
			}),
			lazy: async () => ({ Component: (await import('@/modules/reports/pages/pbx/Layout')).default }),
			children: [
				{
					id: 'pbxCallsReport',
					index: true,
					path: 'calls',
					lazy: async () => ({ Component: (await import('@/modules/reports/pages/pbx/Calls.tsx')).default }),
					loader: () => ({
						meta: {
							title: 'Calls',
							disabled: true,
						} as RouteMeta,
					}),
				},
				{
					id: 'pbxAbandonedReport',
					index: true,
					path: 'abandoned',
					lazy: async () => ({ Component: (await import('@/modules/reports/pages/pbx/Abandoned.tsx')).default }),
					loader: () => ({
						meta: {
							title: 'Abandoned',
							disabled: true,
						} as RouteMeta,
					}),
				},
			],
		},
	],
};
export default route;
